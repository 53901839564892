@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .smooth-scroll {
        scroll-behavior: smooth;
    }
}

$step-color: darkgray;
$bg-color-scroll: #aab2bf;
$bg-color-scroll-2: #d9d9d9;
$bg-color-scoll-track: #d7d7d7;
$border-radius-scroll: 4px;
$width-scroll: 6px;
$height-scroll: 6px;

$bg-blue-scroll: #a6c2f9;

@mixin scroll-custom {
    ::-webkit-scrollbar {
        width: $width-scroll;
        height: $height-scroll;
    }
    ::-webkit-scrollbar-thumb {
        background: $bg-color-scroll;
        border-radius: $border-radius-scroll;
    }
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 $border-radius-scroll $bg-color-scroll-2;
        border-radius: $border-radius-scroll;
        background: $bg-color-scoll-track;
    }
    scrollbar-color: $bg-color-scroll $bg-color-scroll-2;
    scrollbar-width: thin;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include scroll-custom();
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.input-number {
    & .ant-input-number-handler-wrap {
        display: none;
    }
}
.textarea-height {
    textarea {
        max-height: 150px;
    }
}

.voucher-item:hover {
    border: 1px solid lightgray !important;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: $step-color !important;
}
.ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: $step-color !important;
}
